@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "游ゴシック", YuGothic, "YuGothic M", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Verdana,
      "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .bg-colorBlue{
    @apply bg-blue-500
  }
  .bg-colorBlueHover
  {
    @apply bg-blue-500
  }
  .shadow-custom {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 1px rgba(0, 0, 0, 0.1), 1px -1px 1px rgba(0, 0, 0, 0.1), -1px 1px 1px rgba(0, 0, 0, 0.1);
   }
}

.button-blue {
  @apply btn btn-sm border-none h-[40px] bg-colorBlue px-5 font-medium text-white hover:bg-colorBlueHover active:opacity-50;
}

.button-disable {
  @apply btn btn-sm border-none h-[40px] bg-gray-500 px-5 font-medium text-white hover:bg-gray-300 active:opacity-50;
}

.react-datepicker__input-container input {
  @apply input input-bordered text-black font-medium pr-8;
}

.list-select-blue {
  @apply p-2 bg-colorBlue hover:bg-colorBlueHover hover:opacity-60 hover:text-white cursor-pointer;
}

@layer components {
  .closeScrollY {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 0.2s ease-in-out;
  }
  .openScrollY {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all 0.2s ease-in-out;
  }


  .image-icon {
    @apply w-6 h-6 object-cover
  }

  .drawer {
    /* コンテンツ部分はフッタ部分だけ小さくする */
    /*
    height: calc(100% - 2.5vh);
    */
    /* コンテンツ部分はヘッダ・フッタ・マージンを考慮した値とするだけ小さくする */
    /* 100dvh - ヘッダ(2.75rem) - フッタ(2.5rem) */

    /*height: calc(100dvh - 5.25rem); */
    height: 100dvh;
  }

  .drawer-content {
    height: 100dvh;
  }

  .left-side-drawer {
    /*height: calc(100dvh - 5.25rem); */
    height: 100dvh;
    overflow-y: clip;
  }

  .theme-color-hover {
    @apply hover:bg-green-300;
  }

  .grid-row-icon-button-sub-grid {
    @apply text-xl rounded-md hover:bg-green-300 w-[30px] h-[30px];
  }


  .grid-row {
    word-break: break-all;
    /* white-space: normal; */
  }

  .ac-card-container:hover .ac-card-content {
    @apply bg-green-300;
  }

  .page-cancel-btn {
    @apply btn bg-red-600 border-none mr-6 px-8;
  }

  .page-do-btn {
    @apply btn bg-blue-500 border-none px-8;
  }

  .page-disable-do-btn {
    @apply btn bg-gray-300 border-none px-8;
  }

  .border-shadow-blue {
    box-shadow: 0 0 0 3px #78c1f3;
  }
  .closeSidebar {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    visibility: hidden;
    top: 56px;
    right: 0;
    transition: transform 4s ease-in-out;
    position: absolute;
    z-index: 10;
    opacity: 0;
  }
  .openSidebar {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    visibility: visible;
    transition: all 8s ease-in-out;
    position: fixed;
    top: 56px;
    right: 0;
    z-index: 10;
    opacity: 1;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.pagination-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination-container a.active {
  background-color: #068fff;
 /* background-color: #007bff;*/
  color: white;
  border: 1px solid #7cbddb;
  border-radius: 4px;
}

.pagination-container a.disabled {
  border: none;
  cursor: default;
}
/*
.pagination-container a:hover:not(.active) {
  background-color: rgb(238, 238, 238);
}
*/

.table td:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  /* z-index: 11; */
}
.table :where(th, td) {
  padding: 0.5rem;
}

.table th:first-child {
  position: static !important;
}

.list-table td:first-child{
  padding-left: 0 !important;
}

.table-bukken {
  @apply table w-full table-fixed
}
.thead-bukken {
  @apply sticky inset-x-0 top-0 z-40
}
.th-bukken {
  @apply text-[15px] md:text-[16px] h-10
}
.td-bukken {
  @apply grid-row bg-transparent text-[15px] md:text-[16px]
}
.lable-text {
    @apply text-black font-semibold text-[15px] md:text-[16px]
}

@media (max-width: 768px) {
  .action-text {
    display: none;
  }
}

.snc-admin-add {
    @apply bg-blue-500 border-none;
  }

.label{
    justify-content: left;
}

/** VAIX JAPAN 物件管理より一部移植(menuTree)↓↓ **/
/* menuTree @vaix */

.menuTree {
  @apply p-2;
}

:where(.menuTree li:empty) {
  @apply bg-base-content opacity-10;
  margin: 0.5rem 1rem;
  height: 1px;
}

.menuTree :where(li ul) {
  @apply ms-4 ps-2;

  &:before {
    @apply bg-base-content absolute bottom-3 start-0 top-3 w-px opacity-10;
    content: "";
  }
}

.menuTree :where(li:not(.menuTree-title) > *:not(ul):not(details):not(.menuTree-title)),
.menuTree :where(li:not(.menuTree-title) > details > summary:not(.menuTree-title)) {
  @apply rounded-btn px-4 py-2 text-start transition duration-200 ease-out;
  text-wrap: balance;
}

:where(.menuTree li:not(.menuTree-title):not(.disabled) > *:not(ul):not(details):not(.menuTree-title)),
:where(.menuTree li:not(.menuTree-title):not(.disabled) > details > summary:not(.menuTree-title)) {

  &:not(summary):not(.active).focus,
  &:not(summary):not(.active):focus,
  &:is(summary):not(.active):focus-visible {
    @apply bg-base-content/10 text-base-content cursor-pointer outline-none;
  }

  @media (hover: hover) {
    &:not(.active):hover {
      @apply cursor-pointer outline-none;

      @supports (color: oklch(0 0 0)) {
        /* @apply bg-base-content/10; */
      }
    }
  }
}

.menuTree li>*:not(ul):not(.menuTree-title):not(details):active,
.menuTree li>*:not(ul):not(.menuTree-title):not(details).active,
.menuTree li>details>summary:active {
  @apply bg-red-50 text-black hover:bg-red-50 hover:text-black;
}

.menuTree li.disabled {
  @apply text-base-content/30;
}

.menuTree :where(li > details > summary)::-webkit-details-marker {
  @apply hidden;
}

.menuTree :where(li > details > summary):before,
.menuTree :where(li > .menuTree-dropdown-toggle):before {
  @apply justify-self-end;
  display: block;
  margin-top: -0.5rem;
  height: 0.5rem;
  width: 0.5rem;
  transform: rotate(-45deg);
  transition-property: transform, margin-top;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  content: "";
  transform-origin: 75% 75%;
  box-shadow: 2px 2px;
  pointer-events: none;
}

.menuTree :where(li > details[open] > summary):before,
.menuTree :where(li > .menuTree-dropdown-toggle.menuTree-dropdown-show):before {
  transform: rotate(45deg);
  /* margin-top: 0; */
}

.menuTree-title {
  @apply text-base-content/40 px-4 py-2 text-sm font-bold;
}

/* .menuTree.ATFmenu :where(li:not(.menuTree-title) > *:not(ul):not(details):not(.menuTree-title)),
.menuTree.ATFmenu :where(li:not(.menuTree-title) > details > summary:not(.menuTree-title)) {
    grid-auto-columns: minmax(auto, max-content) auto max-content;
    user-select: none;
} */


.menuTree.ATFmenu li:not(.menuTree-title) > *:not(ul):not(details):not(.menuTree-title),
.menuTree.ATFmenu li:not(.menuTree-title) > details > summary:not(.menuTree-title){
  @apply grid grid-flow-col content-start items-center gap-2;
  grid-auto-columns: minmax(auto, max-content) max-content auto;
  user-select: none;
}

.menuTree.ATFmenu li.disabled {
  @apply cursor-not-allowed select-none;
}

.menuTree.ATFmenu li > .menuTree-dropdown:not(.menuTree-dropdown-show) {
  @apply hidden;
}

.menuTree {
  @apply flex flex-col flex-wrap text-sm;

  :where(li ul) {
    @apply relative whitespace-nowrap;
  }

  :where(li:not(.menuTree-title) > *:not(ul):not(details):not(.menuTree-title)),
  :where(li:not(.menuTree-title) > details > summary:not(.menuTree-title)) {
    @apply grid grid-flow-col content-start items-center gap-2;
    grid-auto-columns: minmax(auto, max-content) auto max-content;
    user-select: none;
  }

  & li.disabled {
    @apply cursor-not-allowed select-none;
  }

  :where(li > .menuTree-dropdown:not(.menuTree-dropdown-show)) {
    @apply hidden;
  }
}

:where(.menuTree li) {
  @apply relative flex shrink-0 flex-col flex-wrap items-stretch;

  .badge {
    @apply justify-self-end;
  }
}

/*
スクロールバーは表示する
::-webkit-scrollbar {
  width: 0px;
}
*/

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background: transparent;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: rgba(0, 0, 0, 0.2);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: #068FFF;
  opacity: 1;
}



/* -------------------------------------------------------------------------------------------------------
*
* 新UI
*
* -------------------------------------------------------------------------------------------------------- */

/*
 * フッタ関係
 */
/* 登録ボタン */
.footer-register-button {
    @apply btn h-[5px] p-[2px]
}

/* キャンセルボタン */
.footer-cancel-button {

}

/* その他のボタン */
.footer-other-button {

}

/*
 * グリッド関係
 */
/* ボタン部分のヘッダ */
.grid-header-button {

}

/* 通常ヘッダ */
.grid-headre {

}

/*　先頭ヘッダの固定 */
.data_table .sticky_header:first-child{
  position: sticky !important;
  z-index: 100 !important;
  top:0;
}

.data_table th.sticky_col_header{
  position: sticky !important;
  z-index: 100 !important;
  top:0;
}


.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}

.loader div {
  position: absolute;
  top: 18px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #222;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 0px;
  animation: flip1 0.5s infinite;
}

.loader div:nth-child(2) {
  left: 4px;
  animation: flip2 0.5s infinite;
}

.loader div:nth-child(3) {
  left: 20px;
  animation: flip2 0.5s infinite;
}

.loader div:nth-child(4) {
  left: 32px;
  animation: flip3 0.5s infinite;
}

@keyframes flip1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes flip3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes flip2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(16px, 0);
  }
}

/* custom input border bottom */
.inputBorderBottom {
  border: none;
  border-bottom: .2px solid #191b1d;
  padding: 3px 5px;
  outline: none;
  background: transparent;
}

/* custom disable month */
.disable-month {
  @apply bg-white text-gray-300 ;
  pointer-events: none
}

.loaderCircle {
  border: 4px solid #1353B7;
  border-left-color: transparent;
  border-radius: 50%;
}

.loaderCircle {
  border: 4px solid 1353B7;
  border-left-color: transparent;
  width: 32px;
  height: 32px;
}

.loaderCircle {
  border: 4px solid 1353B7;
  border-left-color: transparent;
  width: 32px;
  height: 32px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@layer components {
  .cssCloseIconDatePicker .react-datepicker__close-icon {
    @apply right-[14px];
  }
}
